export default {

    install(Vue, options) {


        Vue.prototype.$rulesAlfaNum = function (v) {
            const pattern = /^[A-ZÑa-zñáéíóúÁÉÍÓÚ' 0-9]+$/;
            if (!pattern.test(v)) return "Se deben ingresar letras o numeros";

            if (typeof v !== "undefined") {
                return v.length <= 256 || "Max 256. caracteres";
            }
            return true;
        }

        Vue.prototype.$rulesRequerido= function  (value) {

          if (typeof value !== "undefined") {
            if(value!== null && value!== '')
            {
                return true;
            }
            else
            {
                return "Este campo es requerido.";
            }
          }
          return "Este campo es requerido.";

        }
        Vue.prototype.$rulesDni= function  (value) {

          if (typeof value !== "undefined") {

              if  (String(value).length=== 7 || String(value).length === 8)
              {
                  return true;
              }
              else{
                return "Este campo debe contener entre 7 y 8 caracteres sin puntos. Ejemplo: 28787992.";
              }
          }
          else{
            return "Este campo debe contener entre 7 y 8 caracteres sin puntos. Ejemplo: 28787992.";
          }
        }

        Vue.prototype.$rulesMail= function  (value) {
				if(
					/.+@.+\..+/.test(value))
                    {
                        return true;
                    }

                    return "Este campo debe ser un e-mail válido.";


        }

        Vue.prototype.$rulesFloatRequerido= function  (v) {
            if(!!v)
            {
                if (parseFloat(v) > 0){
                    return true;
                }
                else{
                    return "Ingrese un valor mayor a 0";
                }

            }
            else
            {
                return "Este campo es requerido.";
            }
        }

        Vue.prototype.$rulesEnterosPositivos= function  (v) {
            const pattern = /^[0-9]+$/;

            if(pattern.test(v))
                {
                    if (parseInt(v) >= 0)
                    {
                        return true;
                    }
                    return "Mayores que 0";
                }
                return "Campo inválido. Ingrese solo números";
        }


        Vue.prototype.$rulesTelefono = function (v) {
            const pattern = /^\d*$/;
            if (!pattern.test(v)) return "Debe ser numérico";

            if (typeof v !== "undefined") {
                return v.length <= 20 || "Max. 20 caracteres";
            }
            return true;
        }

        Vue.prototype.$rulesCuit = function (v) {
            const pattern = /^\d{11}$/;
            if (!pattern.test(v)) return "Debe ser numérico y tener 11 caracteres";

            return true;
        }

        Vue.prototype.$rulesImg = function (v) {
            if(!!v)
            {
                const pattern = /^$|^.*\.(jpg|jpeg|bmp|png)$/;
                if (!pattern.test(v.name)) return "El archivo debe ser una imagen JPEG, PNG o BMP";
            }
            return true;
        }

        Vue.prototype.$rulesMax50 = function (v) {
            if (typeof v !== "undefined") {
              return v.length <= 50 || "Max. 50 caracteres";
            }
            return true;
          }

        Vue.prototype.$rulesLegajo = function  (value) {

            if (typeof value !== "undefined") {
  
                if  (String(value).length < 20)
                {
                    return true;
                }
                else{
                  return "Este campo no puede contener mas de 20 caracteres.";
                }
            }
            else{
              return "Este campo no puede contener mas de 20 caracteres.";
            }
        }

        Vue.prototype.$rulesFechaMayorAHoy = function(value) {
              const fechaIngresada = new Date(value);
              const fechaActual = new Date();
          
              if (fechaIngresada > fechaActual) {
                return true;
              } else {
                return "La fecha debe ser posterior a la fecha actual.";
              }
          };
          



    }
}
