<script>

  export default {
    methods: {
      $can(permissionName) {
        if (typeof this.$store.state.user.permisos !== "undefined"){
        
          const multipleExist = permissionName.some((value) => 
            this.$store.state.user.permisos.includes(value)
          );
          return multipleExist;
          /*console.log(this.$store.state.user.permisos.includes(permissionName))
          return this.$store.state.user.permisos.includes(permissionName)*/
          /*console.log(permissionName);
          console.log(this.$store.state.user.permisos);
          console.log(this.$store.state.user.permisos.filter(x => x.includes(permissionName)));          
          */

          
        }
        
        return false;
        //return Permisos.toString().indexOf(permissionName) !== -1;
      },

    },
  };
</script>