<template>
  <v-container id="crud" fluid tag="section">
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="'Busqueda de ' + title"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        sort-by="secret"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title
              >{{ title }} del vehiculo {{ vehiculo.marca }}
              {{ vehiculo.modelo }} ({{ vehiculo.patente }})</v-toolbar-title
            >
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>

            <v-dialog v-model="dialog" max-width="50%" persistent>
              <template v-if="visible" v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mx-2"
                  fab
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon blue>mdi-plus</v-icon></v-btn
                >
              </template>
              <v-card>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-toolbar dark color="primary">
                    <v-btn icon dark @click="dialog = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title
                      >{{ formTitle }}
                      {{ vehiculo.marca }}
                      {{ vehiculo.modelo }} ({{
                        vehiculo.patente
                      }})</v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn dark text @click="save" :disabled="!valid"
                        >Guardar</v-btn
                      >
                    </v-toolbar-items>
                  </v-toolbar>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="6" sm="12">
                          <v-autocomplete
                            :items="tipos"
                            item-value="id"
                            item-text="nombre"
                            label="Tipo de Gasto"
                            v-model="editedItem.tipo_gasto_id"
                            :rules="rulesRequired"
                            return-object
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6" sm="12">
                          <v-text-field
                            v-model="editedItem.monto"
                            label="Monto"
                            :rules="rulesRequired"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6" sm="12">
                          <v-menu
                            v-model="menuFecha"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="computedFecha"
                                label="Fecha"
                                persistent-hint
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :rules="rulesRequired"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="editedItem.fecha"
                              no-title
                              @input="menuFecha = false"
                              :max="nowDate"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" md="6" sm="12">
                          <v-menu
                            v-model="menuFechaPago"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="computedFechaPago"
                                label="Fecha de Pago"
                                persistent-hint
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :rules="rulesRequired"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="editedItem.fecha_pago"
                              no-title
                              @input="menuFechaPago = false"
                              :max="nowDate"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6" sm="12">
                          <v-autocomplete
                            :items="estados"
                            item-value="id"
                            item-text="nombre"
                            label="Estado"
                            v-model="editedItem.estado_id"
                            :rules="rulesRequired"
                            return-object
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6" sm="12">
                          <v-autocomplete
                            :items="choferes"
                            item-value="id"
                            item-text="chofer"
                            label="Chofer"
                            v-model="editedItem.chofer_id"
                            return-object
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row>
                        <!-- subidor de foto -->
                        <v-col cols="12" md="12" sm="12">
                          <v-file-input
                            accept="image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            label="Seleccione una imagen"
                            prepend-icon="mdi-camera"
                            v-model="archivo"
                          ></v-file-input>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="12" sm="12">
                          <v-textarea
                            clearable
                            clear-icon="mdi-close-circle"
                            v-model="editedItem.observaciones"
                            label="Observaciones"
                            auto-grow
                            rows="1"
                            row-height="15"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-form>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.monto`]="{ item }">
          <span>{{
            item.monto ? "$" + Number(item.monto).toLocaleString("es-AR") : 0
          }}</span>
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          <span>{{ item.fecha ? $formatDate(item.fecha) : "" }}</span>
        </template>
        <template v-slot:[`item.fecha_pago`]="{ item }">
          <span>{{ item.fecha_pago ? $formatDate(item.fecha_pago) : "" }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-if="visible"
            small
            class="mr-2"
            color="green"
            title="Editar"
            @click="editItem(item.id)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            v-if="visible"
            small
            color="red"
            title="Eliminar"
            @click="deleteItem(item.id, item.tipo_gasto)"
          >
            mdi-delete
          </v-icon>
          <v-icon
            v-if="item.archivo"
            small
            color="blue"
            title="Descargar Documentacion de Gastos"
            @click="download(item)"
          >
            mdi-file-download
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
function title() {
  return "Gastos";
}

export default {
  props: {
    vehiculo: { Object, default: null },
  },
  data: (vm) => ({
    archivo: null,
    menuFecha: false,
    menuFechaPago: false,
    valid: true,
    nowDate: new Date().toISOString().slice(0, 10),
    _method: "PUT",
    autoGrow: true,
    rows: 1,
    title: title(),
    route: "gastos",
    //baseUrlVehiculo: "/api/gastos-vehiculo",
    menu: false,
    modal: false,
    menu2: false,
    dialog: false,
    snackbar: false,
    visible: true,
    text: "Registro Insertado",
    color: "success",
    timeout: 4000,
    rules: [(v) => v.length <= 100 || "Max 100 caracteres"],
    search: "",
    rulesRequired: [(v) => !!v || "Requerido"],
    headers: [
      { text: "Vehiculo", filterable: true, value: "patente" },
      { text: "Tipo Gasto", filterable: true, value: "tipo_gasto" },
      { text: "Chofer", filterable: true, value: "chofer" },
      { text: "Monto", filterable: true, value: "monto" },
      { text: "Estado", filterable: true, value: "estado" },
      { text: "Fecha", filterable: true, value: "fecha" },
      { text: "Fecha de Pago", filterable: true, value: "fecha_pago" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {},
    tipos: [],
    estados: [],
    choferes: [],
    editedItem: { archivo: "" },
  }),

  computed: {
    computedFecha() {
      return this.$formatDate(this.editedItem.fecha);
    },
    computedFechaPago() {
      return this.$formatDate(this.editedItem.fecha_pago);
    },
    formTitle() {
      return this.editedIndex === -1
        ? "Registrar " + this.title
        : "Editar " + this.title;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
    this.getTipos();
    this.getEstados();
    this.getChoferes();
  },

  methods: {
    download(item) {
      if (!item.archivo) {
        Swal.fire("Atención!", "El gasto no tiene archivos adjuntos", "info");
      }
      let fileJson = JSON.parse(item.archivo);
      let vehiculo = this.vehiculo.id;
      let archivo = fileJson.name;
      /*let url =
        "/api/vehiculos-download/" + vehiculo + "/" + archivo + "/gastos";*/
      let url = vehiculo + "/" + archivo + "/gastos";
      this.$axiosApi
        .getDownload("vehiculos_download", url)
        /*axios({
        url: url,
        method: "GET",
        responseType: "blob",
      })*/
        .then((response) => {
          if (response.data.size <= 0) {
            Swal.fire(
              "Atención!",
              "El gasto no tiene archivos adjuntos",
              "info"
            );
            return;
          }
          let blob = new Blob([response.data], {
              type: response.data.type,
            }),
            url = window.URL.createObjectURL(blob);

          window.open(url);
        })
        .catch(console.error);
    },
    upload(id) {
      var fd = new FormData();
      var file = this.archivo;
      fd.append("vehiculo", id);
      let i = 0;
      if (file) {
        fd.append("file[" + i + "]", file, file.name);
        i++;
      }

      this.$axiosApi
        .postFile("vehiculos_upload_gastos", fd)
        /*axios
        .post("/api/vehiculos-upload/gastos", fd, {
          headers: { "Content-Type": "multipart/form-data" },
        })*/
        .then((r) => {
          //subida exitosa
          console.log("upload ok");
          return true;
        })
        .catch((e) => {
          this.snackbar = true;
          this.text =
            "Error al cargar archivos. Contacte al administrador del sistema";
          this.color = "error";
        });
    },
    getEstados() {
      this.$axiosApi
        .get("estados")
        .then((response) => {
          this.estados = response.data;
        })
        .catch((response) => {
          this.snackbar = true;
          this.text =
            "Error al obtener estados. Contacte al administrador del sistema";
          this.color = "error";
        });
    },
    getChoferes() {
      this.$axiosApi
        .get("choferes_list")
        .then((response) => {
          console.log(response.data);
          this.choferes = response.data;
        })
        .catch((response) => {
          this.snackbar = true;
          this.text =
            "Error al obtener choferes. Contacte al administrador del sistema ";
          this.color = "error";
        });
    },
    getTipos() {
      this.$axiosApi
        .get("tipo_gastos")
        .then((response) => {
          this.tipos = response.data;
        })
        .catch((response) => {
          this.snackbar = true;
          this.text =
            "Error al obtener tipos de gastos. Contacte al administrador del sistema ";
          this.color = "error";
        });
    },

    initialize() {
      //let url = this.baseUrlVehiculo + "/" + this.vehiculo.id;
      this.$axiosApi
        .getByCriteria("gastos_vehiculo", this.vehiculo.id)
        .then((r) => {
          this.desserts = r.data;
        })
        .catch(function (error) {
          this.snackbar = true;
          this.text =
            "Error al obtener gastos. Contacte al administrador del sistema ";
          this.color = "error";
        });
    },

    editItem(id) {
      /*axios
        .get(this.baseUrl + "/" + id)*/
      this.$axiosApi
        .getById(this.route, id)
        .then((r) => {
          this.editedIndex = r.data.data.id;
          delete r.data.id;

          this.editedItem = Object.assign({}, r.data.data);
          if (this.editedItem.archivo) {
            let fileJson = JSON.parse(this.editedItem.archivo);
            let file = new File([fileJson], fileJson.name, {
              type: fileJson.type,
              lastModified: fileJson.lastModified,
              size: fileJson.size,
              webkitRelativePath: fileJson.webkitRelativePath,
            });
            //console.log(file);
            this.archivo = file;
          }
          this.editedItem.tipo_gasto_id = r.data.data.tipo_gasto_id
            ? {
                id: Number(r.data.data.tipo_gasto_id),
                nombre: r.data.data.tipo_gasto,
              }
            : null;
          this.editedItem.estado_id = r.data.data.estado_id
            ? {
                id: Number(r.data.data.estado_id),
                nombre: r.data.data.estado,
              }
            : null;
          this.editedItem.chofer_id = r.data.data.chofer_id
            ? {
                id: Number(r.data.data.chofer_id),
                chofer: r.data.data.chofer,
              }
            : null;

          this.dialog = true;
        })
        .catch(function (error) {
          this.snackbar = true;
          this.text =
            "Error al obtener datos. Contacte al administrador del sistema ";
          this.color = "error";
        });
    },

    deleteItem(id, nombre) {
      this.$confirm({
        message: "¿Desea ELIMINAR el " + this.title + " " + nombre + "?",
        button: {
          no: "Cancelar",
          yes: "Confirmar",
        },

        callback: (confirm) => {
          if (confirm) {
            this.$axiosApi.delete(this.route, id).then(
              (response) => {
                this.snackbar = true;
                this.text = "Registro Eliminado";
                this.initialize();
              },
              (error) => {
                this.snackbar = true;
                this.text =
                  "Error al eliminar los datos.Contacte al administrador del sistema";
                this.color = "error";
              }
            );
          }
        },
      });
    },

    close() {
      this.dialog = false;
      this.valid = true;
      this.resetValidation();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    save() {
      if (this.$refs.form.validate()) {
        this.editedItem.vehiculo_id = this.vehiculo.id;
        if (this.archivo) {
          var newObject = {
            lastModified: this.archivo.lastModified,
            lastModifiedDate: this.archivo.lastModifiedDate,
            name: this.archivo.name,
            size: this.archivo.size,
            type: this.archivo.type,
          };

          // then use JSON.stringify on new object
          this.editedItem.archivo = JSON.stringify(newObject);
        }
        if (this.editedIndex > -1) {
          /*let formData = new FormData();
          let url = this.baseUrl + "/" + this.editedIndex;
          formData.append("_method", "PUT");

          axios
            .post(url, {
              _method: "PUT",
              data: this.editedItem,
            })*/
          this.$axiosApi
            .put(this.route, this.editedIndex, this.editedItem)
            .then((r) => {
              if (r.data.status == 200) {
                if (this.archivo) {
                  this.upload(this.vehiculo.id);
                }
                this.snackbar = true;
                this.text = "Registro Actualizado";
                this.color = "success";
                this.initialize();
              } else {
                this.snackbar = true;
                this.text =
                  "Error al actualizar los datos. Contacte al administrador del sistema";
                this.color = "error";
              }
            })
            .catch((e) => {
              this.snackbar = true;
              this.text =
                "Error al actualizar los datos.Contacte al administrador del sistema";
              this.color = "error";
            });
        } else {
          let method = "post";
          let url = this.baseUrl;
          this.$axiosApi
            .post(this.route, this.editedItem)
            /*axios({
            method,
            url,
            data: this.editedItem,
          })*/
            .then((r) => {
              if (this.archivo) {
                this.upload(this.vehiculo.id);
              }
              this.snackbar = true;
              this.text = "Registro Creado";
              this.color = "success";
              this.initialize();
            })
            .catch((e) => {
              console.log(e);
              this.snackbar = true;
              this.text =
                "Error al registrar los datos.Contacte al administrador del sistema";
              this.color = "error";
            });
        }

        this.close();
      }
    },
  },
  mounted() {
    console.log("Componente " + this.baseUrl + " creado");
  },
};
</script>
