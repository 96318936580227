

export default function admin({ next, router }) {
    //console.log(this.$store.state.user);
    if (this.$store.state.user.role_id == 1) {
        Swal.fire({
            icon: "error",
            title: "Acceso Denegado",
            text: "Ingreso por el middleware",
            //footer: '<a href="">Why do I have this issue?</a>'
          });

          return;
      //return router.push({ name: 'login' });
    }
  
    return next();
  }