    export default {

      install(Vue, options) {


          Vue.prototype.$cv = function ( value) {
            //en base al tenant que recibo defino las clases, variables o textos a usar
            let client = this.$keycloak.tokenParsed.foo_tenants[0];
            //client="flota";
            var vars = {
              iveco:{
                color:'#105cfa',
                principal:"primary",
                btnEditar:"orange",
                btnActivo:"green",
                btnInactivo:"red",
                btnEliminar:"red",
                btnVista:'primary',
                titleActivar:"Activar",
                titleDesactivar:"Desactivar",
                filterColor:"grey-lighten-2"
              },
              flota:{
                color:'#ff9800',
                principal:"orange",
                btnEditar:"orange",
                btnActivo:"orange",
                btnInactivo:"orange",
                btnEliminar:"orange",
                btnVista:'orange',
                titleActivar:"Activar",
                titleDesactivar:"Desactivar"

              },
              acara:{
                principal:"orange",
                btneditar:"orange",
              }
            }

            this.$vuetify.theme.themes.dark.primary =vars[client]["color"];
            this.$vuetify.theme.themes.light.primary =vars[client]["color"];
              return vars[client][value];
          }

      }
  }


