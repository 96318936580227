import Vue from 'vue'
import App from './App.vue'
import router from './router'

import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import VueConfirmDialog from 'vue-confirm-dialog'
import Keycloak from 'keycloak-js';
import AxiosApi from "./plugins/axiosApi";

import ClientVar from "./utils/ClientVar";
import Can from "./utils/Can";

import Rules from './utils/Rules.js';
import CrudRequest from './utils/CrudRequest.js';
import Permissions from "@/mixins/Permissions";
import store from './store'

import JsonExcel from "vue-json-excel";
import swal from 'sweetalert2';

import VuetifyMoney from "vuetify-money";
//import 'bootstrap/dist/css/bootstrap.css';
//import 'bootstrap-vue/dist/bootstrap-vue.css';
//import 'sweetalert2/src/sweetalert2.scss';


window.Swal = swal;
Vue.use(VuetifyMoney);

Vue.use(ClientVar);
Vue.use(Can);
Vue.use(Rules);
Vue.use(CrudRequest);
Vue.mixin(Permissions);
Vue.component("downloadExcel", JsonExcel);
Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default);
Vue.component('vehiculo-form-component', require('./views/vehiculo/components/VehiculoForm.vue').default);
Vue.component('vehiculo-details-component', require('./views/vehiculo/components/VehiculoDetails.vue').default);
Vue.component('gastos-component', require('./views/vehiculo/components/Gastos.vue').default);
Vue.component('documentacion-component', require('./views/vehiculo/components/Documentacion.vue').default);
Vue.component('filter-component', require('./views/components/Filter.vue').default);

Vue.config.productionTip = false
let client = '';
let urlKeycloak = process.env.VUE_APP_KEYCLOAK_URL;

/*if(location.host == 'localhost:8081'){
  client = process.env.VUE_APP_KEYCLOAK_CLIENTID_LOCAL;
  //urlKeycloak = process.env.VUE_APP_KEYCLOAK_URL_LOCAL;
}
else{*/
  client = process.env.VUE_APP_KEYCLOAK_CLIENTID;
//}

let initOptions = {
  url: urlKeycloak, realm: process.env.VUE_APP_KEYCLOAK_REALM, clientId: client, onLoad: 'login-required'
}

let keycloak = new Keycloak(initOptions);
keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {

  //console.log(keycloak.hasRealmRole("seguridad")); //verifica rol en el reino
  //console.log(keycloak.hasResourceRole("administrador")); //verifica rol en el cliente
  //console.log(keycloak);
  if (!auth) {
    window.location.reload();
  } else {
    new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: h => h(App, { props: { keycloak: keycloak } })
    }).$mount('#app')
    Vue.prototype.$token = keycloak.idToken
    Vue.prototype.$axiosApi = new AxiosApi(Vue.prototype.$token);
  }

  Vue.prototype.$keycloak = keycloak;

  router.beforeEach((to, from, next) => {
    if (to.meta.isAuthenticated) {
      // Get the actual url of the app, it's needed for Keycloak
      const basePath = window.location.toString()
      if (!Vue.prototype.$keycloak.authenticated) {
        // The page is protected and the user is not authenticated. Force a login.
        Vue.prototype.$keycloak.login({ redirectUri: basePath.slice(0, -1) + to.path })
      } else {
        console.log('update token');
        // The user was authenticated, and has the app role (is authorized). Update the token.
        Vue.prototype.$keycloak.updateToken(70)
          .then(() => {
            next()
          })
          .catch(err => {
            console.error(err)
          })
      }
    } else {
      // This page did not require authentication
      next()
    }
  })

  //Token Refresh

  setInterval(() => {
    Vue.prototype.$token = keycloak.idToken
    keycloak.updateToken(70).then((refreshed) => {
      if (refreshed) {
        console.log('Token refreshed');
        Vue.prototype.$token = keycloak.idToken
        Vue.prototype.$axiosApi = new AxiosApi(Vue.prototype.$token);
      } else {
        //console.log('Token not refreshed, valid for ' + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
      }
    }).catch(() => {
      console.log('Failed to refresh token');
    });
  }, 6000)


}).catch(() => {
  console.log("Authenticated Failed");
});

