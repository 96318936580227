import Vue from 'vue'
import Router from 'vue-router'
//import NotFound from './views/NotFound'
//import Forbidden from './views/Forbidden'
import Login from './views/Login'
import Logout from './views/Logout'
//import Home from './views/Home'
import store from './store'
import admin from './middleware/admin';

Vue.use(Router)

let router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    /*{
      name: 'accesoDenegado',
      path: '/accesoDenegado',
      component: Forbidden,
    },*/
    /*{
        path: '/',
        name: 'home',
        component: Home,
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            requiresVisitor: true,
        }
    },
    {
        path: '/logout',
        name: 'logout',
        component: Logout,
        meta: {
            middleware:"auth",
        }
    },
    {
      path: '/changePassword',
      name: 'changePassword',
      component: () => import('@/views/auth/ChangePassword'),
      meta: {
        middleware:"auth"
      },
    },
    {
      path: '/twoFactor',
      name: 'twoFactor',
      component: () => import('@/views/auth/TwoFactor'),
      meta: {
        middleware:"auth"
      },
    },
    {
      path: '/passwordExpiration',
      name: 'passwordExpiration',
      component: () => import('@/views/auth/PasswordExpiration'),
      meta: {
        middleware:"auth2"
      },
    },*/
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),     
      children: [
        // Dashboard
        {
          name: 'dashboard',
          path: '',
          component: () => import('@/views/dashboard/Dashboard'),
          meta: {
            isAuthenticated: true
          },
        },
        // Pages
        {
          name: 'User Profile',
          path: 'pages/user',
          component: () => import('@/views/dashboard/pages/UserProfile'),
          meta: {
             isAuthenticated: true
          },
        },
        //crud
        {
          name: 'secrets',
          path: '/admin/secrets',
          component: () => import('@/views/cruds/Crud'),
          meta: {
             isAuthenticated: true
          },
        },
        {
          name: 'alertas',
          path: '/alertas/alertas',
          component: () => import('@/views/cruds/Alertas'),
          meta: {
             isAuthenticated: true
          },
        },
        {
          name: 'alertas choferes',
          path: '/alertas/choferes',
          component: () => import('@/views/cruds/Alertas'),
          meta: {
             isAuthenticated: true
          },
          props: { modulo: 'C'}
        },
        {
          name: 'alertas seguros',
          path: '/alertas/seguros',
          component: () => import('@/views/cruds/Alertas'),
          meta: {
             isAuthenticated: true
          },
          props: { modulo: 'S'}
        },
        {
          name: 'alertas vehiculos',
          path: '/alertas/vehiculos',
          component: () => import('@/views/cruds/Alertas'),
          meta: {
             isAuthenticated: true
          },
          props: { modulo: 'V'}
        },
        {
          name: 'tipo_alertas',
          path: '/admin/tipo_alertas',
          component: () => import('@/views/cruds/TipoAlertas'),
          meta: {
             isAuthenticated: true
          },
        },
        {
          name: 'cargos',
          path: '/admin/cargos',
          component: () => import('@/views/cruds/Cargo'),
          meta: {
             isAuthenticated: true
          },
        },
        {
          name: 'tipo_trabajos',
          path: '/admin/tipo_trabajos',
          component: () => import('@/views/cruds/TipoTrabajos'),
          meta: {
             isAuthenticated: true
          },
        },
        {
          name: 'categoria_gastos',
          path: '/admin/categoria_gastos',
          component: () => import('@/views/cruds/CategoriaGastos'),
          meta: {
             isAuthenticated: true
          },
        },
        {
          name: 'gastos',
          path: '/admin/gastos',
          component: () => import('@/views/cruds/Gastos'),
          meta: {
             isAuthenticated: true
          },
        },
        {
          name: 'tipo_documentos',
          path: '/admin/tipo_documentos',
          component: () => import('@/views/cruds/TipoDocumentos'),
          meta: {
             isAuthenticated: true
          },
        },
        {
          name: 'tipo_gastos',
          path: '/admin/tipo_gastos',
          component: () => import('@/views/cruds/TipoGastos'),
          meta: {
             isAuthenticated: true
          },
        },
        {
          name: 'departamentos',
          path: '/admin/departamentos',
          component: () => import('@/views/cruds/Departamento'),
          meta: {
             isAuthenticated: true
          },
        },
        {
          name: 'tipo_licencias',
          path: '/admin/tipo_licencias',
          component: () => import('@/views/cruds/TipoLicencias'),
          meta: {
             isAuthenticated: true
          },
        },
        {
          name: 'servicios',
          path: '/admin/servicios',
          component: () => import('@/views/cruds/Servicios'),
          meta: {
             isAuthenticated: true
          },
        },
        {
          name: 'combustibles',
          path: '/admin/combustibles',
          component: () => import('@/views/cruds/Combustibles'),
          meta: {
             isAuthenticated: true
          },
        },
        {
          name: 'repuestos',
          path: '/admin/repuestos',
          component: () => import('@/views/cruds/Repuestos'),
          meta: {
             isAuthenticated: true
          },
        },
        {
          name: 'service',
          path: '/admin/service',
          component: () => import('@/views/cruds/Service'),
          meta: {
             isAuthenticated: true
          },
        },
        {
          name: 'seguros',
          path: '/admin/seguros',
          component: () => import('@/views/cruds/Seguro'),
          meta: {
             isAuthenticated: true
          },
        },
        {
          name: 'choferes',
          path: '/choferes/choferes',
          component: () => import('@/views/cruds/Choferes'),
          meta: {
             isAuthenticated: true
          },
        },
        {
          name: 'pasajeros',
          path: '/pasajeros/pasajeros',
          component: () => import('@/views/cruds/Pasajeros'),
          meta: {
             isAuthenticated: true
          },
        },
        {
          name: 'empresas',
          path: '/admin/empresas',
          component: () => import('@/views/cruds/Empresas'),
          meta: {
             isAuthenticated: true
          },
        },
        {
          name: 'playas',
          path: '/cocheras/playas',
          component: () => import('@/views/cruds/Playas'),
          meta: {
             isAuthenticated: true
          },
        },
        {
          name: 'zonas',
          path: '/admin/zonas',
          component: () => import('@/views/cruds/Zona'),
          meta: {
             isAuthenticated: true
          },
        },
        {
          name: 'conductores',
          path: '/conductores/conductores',
          component: () => import('@/views/conductor/Conductor'),
          meta: {
             isAuthenticated: true
          },
        },
        {
          name: 'vehiculos',
          path: '/vehiculos/vehiculos',
          component: () => import('@/views/vehiculo/Vehiculo'),
          meta: {
             isAuthenticated: true
          },
        },
        {
          name: 'estados',
          path: '/admin/estados',
          component: () => import('@/views/cruds/Estados'),
          meta: {
             isAuthenticated: true
          },
        },
        {
          name: 'tipo_vehiculos',
          path: '/admin/tipo_vehiculos',
          component: () => import('@/views/cruds/TipoVehiculo'),
          meta: {
             isAuthenticated: true
          },
        },
        {
          name: 'marcas',
          path: '/admin/marcas',
          component: () => import('@/views/cruds/Marca'),
          meta: {
             isAuthenticated: true
          },
        },
        {
            name: 'modelos',
            path: '/admin/modelos',
            component: () => import('@/views/cruds/Modelo'),
            meta: {
              isAuthenticated: true
           },
          },
        {
          name: 'Configuraciones',
          path: '/admin/configuraciones',
          component: () => import('@/views/cruds/Configuraciones'),
          meta: {
            isAuthenticated: true
         },
        },
        {
          name: 'Auditoria',
          path: '/admin/auditoria',
          component: () => import('@/views/cruds/Auditoria'),
          meta: {
          middleware:"auth"
          },
        },
        {
          name: 'Firewall',
          path: '/admin/firewall',
          component: () => import('@/views/cruds/Firewall'),
          meta: {
          middleware:"auth"
          },
        },
        {
          name: 'Permisos',
          path: '/admin/permission',
          component: () => import('@/views/cruds/Permission'),
        },
        {
          name: 'Roles',
          path: '/admin/role',
          component: () => import('@/views/cruds/Role'),
        },
        {
          name: 'Usuarios',
          path: '/admin/usuarios',
          component: () => import('@/views/cruds/Usuarios'),
          meta: {
            middleware: admin,
          }
        },

        /*{
          path: '/404',
          name: '404',
          component: NotFound,
        },*/
        /*{
            path: '*',
            redirect: '/404',
        },*/
      ],
    },
  ],

})
router.beforeEach((to, from, next) => {
  if(to.meta.middleware=="auth"){
    //console.log(to.meta.middleware);
    //console.log(store.state.authenticated);
    if(store.state.authenticated){
      next()
    }else{
        next({name:"login"})
    }
  }
  else{
    next();
  }




})
/*router.beforeEach((to, from, next) => {
  const loggedIn = this.$store.state.user

console.log(loggedIn);
  if (to.matched.some(record => record.meta.auth) && !loggedIn) {
    next('/login')
    return
  }
  next()
})*/
/*
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let token = document.head.querySelector('meta[name="csrf-token"]');
    console.log(token)
    if (token == null) {
      Swal.fire({title:"Sin Autorizacion" ,
      text: "Presione Aceptar para iniciar sesion nuevamente",
      icon: "error",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Aceptar",
      },
    ).then((result) => {
      if (result.isConfirmed) {
        window.location.href = '/login';
      }
    })
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    } else {

        next()
      }

  }  else {
    next()
  }
})*/

export default router;