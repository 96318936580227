import axios from "axios";
import {apiRoute} from "@/helper/apiRoute";



export default class AxiosApi {
    constructor(token) {
        const axiosApi =   axios.create({
          withCredentials: false,
        });
        this.axiosApi = axiosApi;
        this.token = token;
        this.routes = apiRoute;
    }

    /**
     *
     * @param headersOption
     * @returns {{headers: (*&{Authorization: (*|string)})}}
     */
        getRequestOptions(headersOption) {



          let headersReq = {
                  accept: 'application/json',
                  "Authorization":  `Bearer ${this.token}`,
                  "Access-Control-Allow-Origin": "*",
                  "Accept": "*/*",
                  "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                  "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
                  ...headersOption
          };





          return {
              headers: headersReq
          };
      }

      /**
       *
       * @param {*} url
       * @param {*} methodRequest
       * @param {*} dataInput
       * @param {*} additionalHeaders
       * @returns
       */

      sendRequest(url, methodRequest, dataInput = null, additionalHeaders = {}) {
          let baseUrl = '';
          //console.log(dataInput);
          //baseUrl = (location.host == 'localhost:8081')?process.env.VUE_APP_API_URL_LOCAL:process.env.VUE_APP_API_URL;
          baseUrl = process.env.VUE_APP_API_URL;
          
          const requestOptions = this.getRequestOptions(additionalHeaders);
          
          const dataForm = dataInput ? JSON.stringify(dataInput) : null;
          const promise =  this.axiosApi({baseURL: baseUrl+'/', url: url, method: methodRequest, data: dataForm, ...requestOptions})
          return promise.then((response) => response);
      }

      sendRequestFile(url, methodRequest, dataInput = null, additionalHeaders = {}) {
        let baseUrl = '';
        //console.log(dataInput);
        //baseUrl = (location.host == 'localhost:8081')?process.env.VUE_APP_API_URL_LOCAL:process.env.VUE_APP_API_URL;
        baseUrl = process.env.VUE_APP_API_URL;
        
        const requestOptions = this.getRequestOptions(additionalHeaders);
        
        const dataForm = dataInput;
        const promise =  this.axiosApi({baseURL: baseUrl+'/', url: url, method: methodRequest, data: dataForm, ...requestOptions})
        return promise.then((response) => response);
    }

      /**
       *
       * @param {*} route
       * @returns
       */
      get(route) {
          const url = this.routes[route];
          return this.sendRequest(url, 'get')
              .then((data) => data);
      }

      getByQuery(route, query) {
        const url = this.routes[route]+"?" +query;
        return this.sendRequest(url, 'get')
            .then((data) => data);
      }

      getById(route,id) {
        const url = this.routes[route]+"/"+id;

        return this.sendRequest(url, 'get')
            .then((data) => data);
      }
      getByCriteria(route,criteria) {
        const url = this.routes[route]+"/"+criteria;       
        return this.sendRequest(url, 'get')
            .then((data) => data);
      }
      getDownload(route,criteria) {
        let baseUrl = process.env.VUE_APP_API_URL;
        let additionalHeaders = '';        
        const url = this.routes[route]+"/"+criteria;
        const requestOptions = this.getRequestOptions(additionalHeaders);                    
        const promise =  this.axiosApi({baseURL: baseUrl+'/', url: url, method: 'GET',responseType: "blob",...requestOptions})
        
        return promise.then((response) => response);               
      }
      /**
       *
       * @param {*} route
       * @param {*} userForm
       * @returns
       */

      post(route,userForm) {
        const url = this.routes[route];
        let data = userForm;
        let additionalHeader = {'Content-Type': 'application/json'};
        return this.sendRequest(url, 'post', data, additionalHeader)
            .then((data) => data);
      }

      postFile(route,userForm) {
        const url = this.routes[route];        
        let data = userForm;
        let additionalHeader = {'Content-Type': 'multipart/form-data'};
        return this.sendRequestFile(url, 'post', data, additionalHeader)
            .then((data) => data);
      }

      put(route, id, userForm) {
        const url = this.routes[route]+'/'+id;

        let data = userForm;    
        let additionalHeader = {'Content-Type': 'application/json'};
        return this.sendRequest(url, 'put', data, additionalHeader)
            .then((data) => data);
        /*
        const url = process.env.VUE_APP_API_URL+'/'+this.routes[route]+'/'+id;
        let additionalHeader = {'Content-Type': 'application/json'};
        let requestOptions = this.getRequestOptions(additionalHeader);
        const promise = axios
            .post(url, {
              _method: "PUT",
              data: userForm,
              headers: requestOptions,
            })



          return promise.then((response) => response).then((data) => data);

        */
      }

      delete(route,id){
      
        const url = this.routes[route]+"/"+id;
        return this.sendRequest(url, 'delete')
            .then((data) => data);
			}


  }
