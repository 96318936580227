export const apiRoute = Object.freeze({
    alertas:"api/alertas",
    audits:"api/audits",
    cargos: "api/cargos",
    categoria_gastos: "api/categoria_gastos",
    choferes: "api/choferes",
    choferes_enable: "api/choferes_enable",
    choferes_list: "api/getListChoferes",
    conductores: "api/conductores",
    combustibles: "api/combustibles",
    configuraciones: "api/configuraciones",
    departamentos: "api/departamentos",
    departamentos_enable: "api/departamentos_enable",
    empresas: "api/empresas",
    estados: "api/estados",
    firewall: "api/firewall",
    getListVehiculos:"api/getListVehiculos",
    getListChoferes:"api/getListChoferes",
    getListTipoAlertas: "api/getListTipoAlertas",
    getAlertasByModulo: "api/getAlertasByModulo",
    gastos: "api/gastos",
    gastos_enable: "api/gastos_enable",
    gastos_vehiculo: "api/gastos_vehiculo",
    documentacion:"api/documentacion",
    documentacion_vehiculo:"api/documentacion_vehiculo",
    marcas: "api/marcas",
    marcas_enable: "api/marcas_enable",
    modelos: "api/modelos",
    modelos_marca: "api/getModelosMarca",
    pasajeros: "api/pasajeros",
    pasajeros_enable: "api/pasajeros_enable",
    permissions: "api/permissions",
    playas: "api/playas",
    playas_enable: "api/playas_enable",
    repuestos: "api/repuestos",
    roles: "api/roles",
    service: "api/service",
    servicios: "api/servicios",
    seguros: "api/seguros",
    talleres: "api/talleres",
    tipo_alertas: "api/tipo_alertas",
    tipo_documentos: "api/tipo_documentos",
    tipo_documentos_enable: "api/tipo_documentos_enable",
    tipo_gastos: "api/tipo_gastos",
    tipo_gastos_enable:"api/tipo_gastos_enable",
    tipo_licencias: "api/tipo_licencias",
    tipo_licencias_enable: "api/tipo_licencias_enable",
    tipo_trabajos: "api/tipo_trabajos",
    tipo_trabajos_enable: "api/tipo_trabajos_enable",
    tipo_vehiculos: "api/tipo_vehiculos",
    tipo_vehiculos_enable: "api/tipo_vehiculos_enable",
    users: "api/users",
    vehiculos: "api/vehiculos",
    vehiculos_export: "api/vehiculos_export",    
    vehiculos_upload:"api/vehiculos_upload",
    vehiculos_download:"api/vehiculos_download",
    vehiculos_upload_gastos:"api/vehiculos_upload/gastos",
    vehiculos_upload_documentacion:"api/vehiculos_upload/documentacion",
    zonas: "api/zonas",
    getUser:"api/getUser",
    marcas_modelos: "api/marcas_modelos",
    marcas_modelos_enable: "api/marcas_modelos_enable",
    marcas_upload:"api/marcas_upload",
  });
