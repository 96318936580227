<template>
  <v-card>
    <v-toolbar dark color="primary" class="flex-grow-0">
      <v-btn icon dark @click="close()">
        <v-icon class="white--text">mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title class="white--text"
        ><b>{{ titleToolbar }}</b></v-toolbar-title
      >
    </v-toolbar>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" md="12" sm="12">
          <v-tabs background-color="black" left dark icons-and-text>
            <v-tab href="#vehiculo">
              Datos del Vehiculo
              <v-icon>mdi-truck</v-icon>
            </v-tab>
            <v-tab-item value="vehiculo">
              <v-card outlined>
                <v-card-text>
                  <vehiculo-form-component
                    v-if="dataVehiculo"
                    :key="componentKey"
                    ref="vehiculoForm"
                    v-bind:vehiculo="dataVehiculo"
                    @send-message="close(dataVehiculo)"
                  ></vehiculo-form-component>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab href="#gastos">
              Gastos
              <v-icon>mdi-cash-fast</v-icon>
            </v-tab>
            <v-tab-item value="gastos">
              <v-card outlined>
                <v-card-text>
                  <!--
                                    <gastos-component
                                        :key="componentKey"
                                        v-bind:vehiculo="dataVehiculo"
                                    >
                                    </gastos-component>
                                    -->
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab href="#mapa">
              Mapa
              <v-icon>mdi-map-outline</v-icon>
            </v-tab>
            <v-tab-item value="mapa">
              <v-card outlined :key="componentKey">
                <v-card-text> </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab href="#documentacion">
              Documentacion
              <v-icon>mdi-file-document-multiple-outline</v-icon>
            </v-tab>
            <v-tab-item value="documentacion">
              <v-card outlined :key="componentKey">
                <v-card-text>
                  <documentacion-component
                    :key="componentKey"
                    v-bind:vehiculo="dataVehiculo"
                  >
                  </documentacion-component>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab href="#kms">
              Kilometros
              <v-icon>mdi-road</v-icon>
            </v-tab>
            <v-tab-item value="kms">
              <v-card outlined :key="componentKey">
                <v-card-text></v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab href="#alertas">
              Alertas
              <v-icon>mdi-alert-circle-outline</v-icon>
            </v-tab>
            <v-tab-item value="alertas">
              <v-card outlined :key="componentKey">
                <v-card-text> </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab href="#asignaciones">
              Asignaciones
              <v-icon>mdi-account-supervisor</v-icon>
            </v-tab>
            <v-tab-item value="asignaciones">
              <v-card outlined :key="componentKey">
                <v-card-text> </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab href="#repuestos">
              Repuestos
              <v-icon>mdi-car-cog</v-icon>
            </v-tab>
            <v-tab-item value="repuestos">
              <v-card outlined :key="componentKey">
                <v-card-text> </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<style scoped>
* {
  box-sizing: border-box;
}

.zoom {
  transition: transform 0.2s;
  margin: 0 auto;
}

.zoom:hover {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(1.5);
}

.text-fields-row {
  display: flex;
  font-size: small;
}
.x-btn-grid {
  flex: unset;
  background-color: bisque;
  margin: 0 5px;
}
</style>
<script>
export default {
  props: {
    dataVehiculo: Object,
  },
  data: (vm) => ({
    readonlyDP: true,
    panel: [0, 1, 2, 3, 4, 5, 6],
    panelDispositivo: [0, 1, 2],
    valid: true,
    titleToolbar: "",
    visible: true,
    dialog: false,
    componentKey: 0,
    dataPersona: {},
  }),
  watch: {},
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    getPrivs() {
      if (this.$store.state.role_id == 1 || this.$store.state.role_id == 2) {
        this.showRnpr = true;
      }
      /*console.log(this.$store.state.role_id);*/
    },

    close() {
      //this.dialog = false;
      this.$emit("send-message");
    },
    itemRowBackground: function (item) {
      return !item.fecha_egreso ? "" : "red--text";
    },
    forceRerender(child) {
      this.componentKey += 1;
    },
    capitalize(s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
  },
  created() {
    console.log();

    //this.getPrivs();
    //this.getPersonDetails(this.person);
  },
  mounted() {
    if (this.dataVehiculo) {
      this.titleToolbar =
        this.dataVehiculo.marca +
        " " +
        this.dataVehiculo.modelo +
        "(" +
        this.dataVehiculo.patente +
        ")";
    }

    console.log("Componente Vehiculo Details mounted");
  },
};
</script>
