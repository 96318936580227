<template>
  <v-card-text outlined>
    <v-form
      ref="formData"
      v-model="valid"
      lazy-validation
      class="justify-center"
    >
      <v-row>
        <v-col cols="12" md="8" sm="12">
          <v-card class="d-flex flex-column">
            <v-card-text class="primary white--text mb-3">
              Información Principal</v-card-text
            >
            <v-container>
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-img
                    max-height="150"
                    max-width="200"
                    :src="urlMarca"
                    class="ml-auto mr-auto"
                  ></v-img>
                  <v-img
                    max-height="300"
                    max-width="400"
                    :src="urlPreview"
                    class="ml-auto mr-auto"
                  ></v-img>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-row>
                    <v-col cols="12" md="12" sm="12" class="pa-0 pl-2 pr-2">
                      <v-autocomplete
                        :items="marcas"
                        item-value="id"
                        item-text="nombre"
                        label="Marca"
                        v-model="form.marca_id"
                        :rules="rulesRequired"
                        return-object
                        filled
                        dense
                        @change="getModelos()"
                      >
                        <template v-slot:item="{ item }">
                          <v-row class="row-marcas-select">
                            <span class="label-marcas-select">{{
                              item.nombre
                            }}</span>
                            <v-img
                              class="logo-marcas-select"
                              :src="item.logo_url"
                              loading="lazy"
                            />
                          </v-row>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="10" sm="10" class="pa-0 pl-2 pr-2">
                      <v-autocomplete
                        :items="modelos"
                        item-value="id"
                        item-text="nombre"
                        label="Modelo"
                        v-model="form.modelo_id"
                        return-object
                        filled
                        dense
                        @change="setFoto()"
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" md="2" sm="2" class="pa-0 pl-2 pr-2">
                      <v-text-field
                        v-model="form.anio"
                        label="Año"
                        :rules="rulesNumberYearRequired"
                        filled
                        dense
                        placeholder="0000"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="12" class="pa-0 pl-2 pr-2">
                      <v-text-field
                        v-model="form.version"
                        label="Version"
                        :rules="rulesRequiredMinMax"
                        filled
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="12" class="pa-0 pl-2 pr-2">
                      <v-text-field
                        v-model="form.patente"
                        label="Patente"
                        :rules="rulesPatente"
                        filled
                        dense
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="12" sm="12" class="pa-0 pl-2 pr-2">
                      <v-text-field
                        v-model="form.color"
                        label="Color"
                        filled
                        dense
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" md="6" sm="12" class="pa-0 pl-2 pr-2">
                      <v-autocomplete
                        :items="tipos"
                        item-value="id"
                        item-text="nombre"
                        label="Tipo de Vehiculo"
                        v-model="form.tipo_vehiculo_id"
                        :rules="rulesRequired"
                        return-object
                        cols="6"
                        filled
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6" sm="12" class="pa-0 pl-2 pr-2">
                      <v-autocomplete
                        :items="combustibles"
                        item-value="id"
                        item-text="nombre"
                        label="Combustible"
                        v-model="form.combustible_id"
                        :rules="rulesRequired"
                        return-object
                        cols="6"
                        filled
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="12" sm="12" class="pa-0 pl-2 pr-2">
                      <v-text-field
                        v-model="form.chasis"
                        label="Chasis"
                        :rules="rulesRequiredMinMax"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12" sm="12" class="pa-0 pl-2 pr-2">
                      <v-text-field
                        v-model="form.motor"
                        label="Numero de Motor"
                        :rules="rulesRequiredMinMax"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12" sm="12" class="pa-0 pl-2 pr-2">
                      <v-textarea
                        clearable
                        clear-icon="mdi-close-circle"
                        v-model="form.observaciones"
                        label="Observaciones"
                        auto-grow
                        rows="1"
                        row-height="15"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <!-- subidor de foto -->
              <!--
								<v-row style="margin-top: -82px">                                                                
                                <v-col cols="12" md="6" sm="12">
                                    <v-file-input
                                        @change="selectFile()"
                                        accept="image/png, image/jpeg, image/bmp"
                                        label="Seleccione una imagen"
                                        prepend-icon="mdi-camera"
                                        v-model="form.foto"
                                    ></v-file-input>
                                </v-col>                                
                            </v-row>
							-->
            </v-container>
          </v-card>
        </v-col>

        <v-col cols="12" md="4" sm="12">
          <v-card class="d-flex flex-column">
            <v-card-text class="primary white--text mb-3">
              Información Adicional</v-card-text
            >
            <v-container>
              <v-row>
                <v-col cols="12" md="12" sm="12" class="pa-0 pl-2 pr-2">
                  <v-autocomplete
                    :items="estados"
                    item-value="id"
                    item-text="nombre"
                    label="Estado"
                    v-model="form.estado_id"
                    :rules="rulesRequired"
                    return-object
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" md="6" sm="6" class="pa-0 pl-2 pr-2">
                  <v-menu
                    v-model="menuFechaAdquisicion"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="computedFechaAdquisicion"
                        label="Fecha de Adquisicion"
                        persistent-hint
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="rulesRequired"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.fecha_adquisicion"
                      no-title
                      @input="menuFechaAdquisicion = false"
                      :max="nowDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6" sm="6" class="pa-0 pl-2 pr-2">
                  <v-menu
                    v-model="menuVencimientoCedula"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="computedVencimientoCedula"
                        label="Vto. Cedula Verde"
                        persistent-hint
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="rulesRequired"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.vencimiento_cv"
                      no-title
                      @input="menuVencimientoCedula = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6" sm="6" class="pa-0 pl-2 pr-2">
                  <v-menu
                    v-model="menuVencimientoVtv"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="computedVencimientoVtv"
                        label="Vto. VTV"
                        persistent-hint
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="rulesRequired"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.vencimiento_vtv"
                      no-title
                      @input="menuVencimientoVtv = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="6" sm="6" class="pa-0 pl-2 pr-2">
                  <v-menu
                    v-model="menuFechaGrabado"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="computedFechaGrabado"
                        label="Fecha de Grabado Autopartes"
                        persistent-hint
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="rulesRequired"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.autopartes"
                      no-title
                      @input="menuFechaGrabado = false"
                      :max="nowDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <!-- talleres tiene q ser select multiple -->
                <v-col cols="12" md="12" sm="12" class="pa-0 pl-2 pr-2">
                  <v-autocomplete
                    :items="talleres"
                    item-value="id"
                    item-text="nombre"
                    label="Taller"
                    v-model="form.talleres"
                    return-object
                    multiple
                  ></v-autocomplete>
                </v-col>
                <!-- zonas tiene q ser select multiple -->
                <v-col cols="12" md="12" sm="12" class="pa-0 pl-2 pr-2">
                  <v-autocomplete
                    :items="zonas"
                    item-value="id"
                    item-text="nombre"
                    label="Zona"
                    v-model="form.zonas"
                    return-object
                    multiple
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="12" sm="12" class="pa-0 pl-2 pr-2">
                  <v-autocomplete
                    :items="deptos"
                    item-value="id"
                    item-text="nombre"
                    label="Departamento"
                    v-model="form.departamento_id"
                    :rules="rulesRequired"
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <v-row v-if="vehiculo">
            <v-col
              cols="12"
              align="right"
              md="12"
              sm="12"
              class="pa-0 pl-2 pr-2"
            >
              <v-btn
                :disabled="!valid"
                color="primary"
                class="mr-4"
                @click="save()"
              >
                Guardar Cambios
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-card-text>
</template>

<script>
export default {
  props: {
    vehiculo: { Object, default: null },
  },
  data: (vm) => ({
    route: "vehiculos",
    panel: [0],
    menuFechaGrabado: false,
    menuFechaAdquisicion: false,
    menuVencimientoCedula: false,
    menuVencimientoVtv: false,
    rules: [(v) => v.length <= 100 || "Max 100 caracteres"],
    rulesRequired: [(v) => !!v || "Requerido"],
    rulesNumberRequired: [
      (v) => !!v || "Requerido",
      (v) => {
        const pattern = /\d{1,8}/;
        return pattern.test(v) || "Numero Inválido.";
      },
      (v) => v.length <= 20 || "Max 20 caracteres",
    ],
    rulesNumber: [
      (v) => {
        const pattern = /\d{1,8}/;
        return v ? pattern.test(v) || "Numero Inválido." : true;
      },
      (v) => v.length <= 10 || "Max 10 caracteres",
    ],
    rulesNumberYearRequired: [
      (v) => !!v || "Requerido",
      (v) => {
        const pattern = /\d{1,8}/;
        return pattern.test(v) || "Numero Inválido.";
      },
      (v) => (v && v.length == 4) || "Ingrese 4 numeros",
      (v) =>
        v <= new Date().getFullYear() ||
        "El año no puede ser mayor al año actual",
    ],
    rulesPatente: [
      (v) => !!v || "Requerido",
      (v) => (v && v.length <= 7) || "Patente inválida",
    ],
    rulesRequiredMinMax: [
      (v) => !!v || "Requerido",
      (v) => (v && v.length <= 50) || "Max 50 caracteres",
      (v) => (v && v.length >= 3) || "Min 3 caracteres",
    ],
    valid: true,
    nowDate: new Date().toISOString().slice(0, 10),
    currYear: new Date().getFullYear(),
    tipos: [],
    marcas: [],
    modelos: [],
    zonas: [],
    talleres: [],
    deptos: [],
    estados: [],
    versiones: [],
    combustibles: [],
    form: { anio: 0 },
    valid: true,
    checkbox: false,
    titleToolbar: "",
    visible: true,
    dialog: false,
    urlPreview: "",
    urlMarca: "",
  }),
  watch: {},
  computed: {
    computedFechaGrabado: {
      get() {
        return this.formatDate(this.form.autopartes);
      },
      set(newComputedFechaGrabado) {
        return newComputedFechaGrabado;
      },
    },
    computedFechaAdquisicion: {
      get() {
        return this.formatDate(this.form.fecha_adquisicion);
      },
      set(newComputedFechaAdquisicion) {
        return newComputedFechaAdquisicion;
      },
    },
    computedVencimientoCedula: {
      get() {
        return this.formatDate(this.form.vencimiento_cv);
      },
      set(newComputedVencimientoCedula) {
        return newComputedVencimientoCedula;
      },
    },
    computedVencimientoVtv: {
      get() {
        return this.formatDate(this.form.vencimiento_vtv);
      },
      set(newComputedVencimientoVtv) {
        return newComputedVencimientoVtv;
      },
    },
  },
  methods: {
    save() {
      let textSwal = "Presione Aceptar para confirmar los cambios realizados";
      let titleSwal = "Guardar cambios?";

      if (this.$refs.formData.validate()) {
        Swal.fire({
          title: titleSwal,
          text: textSwal,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.value) {
            Swal.fire({
              title: "Aguarde un instante!",
              icon: "info",
              html: "Guardando Datos del Vehiculo",
              allowOutsideClick: false,
              showConfirmButton: false,
              onBeforeOpen: () => {
                Swal.showLoading();
              },
            });

            let formData = new FormData();
            let url = this.baseUrl + "/" + this.vehiculo.id;
            /*formData.append("_method", "PUT");

            axios
              .post(url, {
                _method: "PUT",
                data: this.form,
              })*/
            this.$axiosApi
              .put(this.route, this.vehiculo.id, this.form)
              .then((r) => {
                Swal.close();
                if (typeof r !== "undefined" && r !== null) {
                  let titleSwalOk =
                    this.vehiculo.marca +
                    " " +
                    this.vehiculo.modelo +
                    "(" +
                    this.vehiculo.patente +
                    ")";
                  Swal.fire({
                    icon: "success",
                    title: "Operación Exitosa!",
                    text:
                      "Se actualizaron los datos del vehiculo " + titleSwalOk,
                  });

                  setTimeout(
                    function () {
                      this.close();
                    }.bind(this),
                    1500
                  );
                } else {
                  Swal.fire({
                    icon: "warning",
                    title: "Error al actualizar el vehiculo",
                    html: "Error: " + e.message,
                  });
                  //this.$confirm.close();
                  return;
                }
              })
              .catch((e) => {
                Swal.close();
                Swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "No se pudo actualizar el vehiculo. Error (" + e + ")",
                  footer: '<a href="">Contacte con el administrador</a>',
                });
              });
          }
        });
      }
    },
    setFoto() {
      if (this.form.modelo_id.foto) {
        this.urlPreview = this.form.modelo_id.foto;
        this.form.foto = this.form.modelo_id.foto;
        //this.selectFile();
      } else {
        this.urlPreview = "/images/car_no_image_2.jpg";
        this.form.foto = null;
      }
    },
    getModelos() {
      //console.log("get modelos");
      //console.log(this.form.marca_id);
      if (this.form.marca_id) {
        /*var marcas = this.marcas;
                let marca_id = this.form.marca_id.id;
                console.log(this.form.marca_id.id);
                let dataMarca = marcas.filter(function (item) {
                    return item.id == marca_id;
                });*/

        //console.log("dataMarca");
        //console.log(dataMarca);
        //this.urlMarca = dataMarca[0].logo_url;
        this.urlMarca = this.form.marca_id.logo_url;

        this.modelos = [];
        let urlModels = "api/getModelos/" + this.form.marca_id.id;
        /*axios
          .get(urlModels)*/
        this.$axiosApi
          .getByCriteria("modelos_marca", this.form.marca_id.id)
          .then((response) => {
            this.modelos = response.data;
          })
          .catch((response) => {
            this.snackbar = true;
            this.text = "Error al cargar modelos. Error: " + response.message;
            this.color = "error";
          });
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    getTipos() {
      this.$axiosApi
        .get("tipo_vehiculos")
        .then((response) => {
          this.tipos = response.data;
        })
        .catch((response) => {
          this.snackbar = true;
          this.text = "Error al obtener tipos. Error: " + response.message;
          this.color = "error";
        });
    },
    getCombustibles() {
      this.$axiosApi
        .get("combustibles")
        .then((response) => {
          this.combustibles = response.data;
        })
        .catch((response) => {
          this.snackbar = true;
          this.text =
            "Error al obtener combustibles. Error: " + response.message;
          this.color = "error";
        });
    },
    getMarcas() {
      this.$axiosApi
        //.get("api/getMarcasActivas")
        .get("marcas")
        .then((response) => {
          this.marcas = response.data;
        })
        .catch((response) => {
          this.snackbar = true;
          this.text = "Error al obtener marcas. Error: " + response.message;
          this.color = "error";
        });
    },
    getTalleres() {
      this.$axiosApi
        .get("talleres")
        .then((response) => {
          this.talleres = response.data;
        })
        .catch((response) => {
          this.snackbar = true;
          this.text = "Error al obtener talleres. Error: " + response.message;
          this.color = "error";
        });
    },
    getZonas() {
      this.$axiosApi
        .get("zonas")
        .then((response) => {
          this.zonas = response.data;
        })
        .catch((response) => {
          this.snackbar = true;
          this.text = "Error al obtener zonas. Error: " + response.message;
          this.color = "error";
        });
    },
    getDeptos() {
      this.$axiosApi
        .get("departamentos")
        .then((response) => {
          this.deptos = response.data;
        })
        .catch((response) => {
          this.snackbar = true;
          this.text =
            "Error al obtener departamentos. Error: " + response.message;
          this.color = "error";
        });
    },
    getEstados() {
      this.$axiosApi
        .get("estados")
        .then((response) => {
          this.estados = response.data;
        })
        .catch((response) => {
          this.snackbar = true;
          this.text = "Error al obtener estados. Error: " + response.message;
          this.color = "error";
        });
    },
    validar() {
      //console.log("acceso a validar");
      //console.log(this.$refs.formData.validate());
      return this.$refs.formData.validate();
    },
    reset() {
      this.$refs.formData.reset();
    },
    resetValidation() {
      this.$refs.formData.resetValidation();
    },
    getPrivs() {
      if (this.$store.state.role_id == 1 || this.$store.state.role_id == 2) {
      }
    },
    selectFile() {
      this.urlPreview = URL.createObjectURL(this.form.foto);
    },
    close() {
      //this.dialog = false;
      this.reset();
      this.$emit("send-message");
    },
  },
  created() {
    this.getTipos();
    this.getMarcas();
    this.getZonas();
    this.getTalleres();
    this.getDeptos();
    this.getEstados();
    this.getCombustibles();
    if (this.vehiculo) {
      this.form = this.vehiculo;
      this.getModelos();
      this.setFoto();
    }
  },
  mounted() {
    console.log("Componente Vehiculo Form creado");
  },
};
</script>
