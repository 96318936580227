<template>
  <v-container id="crud" fluid tag="section">
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="'Busqueda de ' + title"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        sort-by="secret"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title
              >{{ title }} del vehiculo {{ vehiculo.marca }}
              {{ vehiculo.modelo }} ({{ vehiculo.patente }})</v-toolbar-title
            >
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>

            <v-dialog v-model="dialog" max-width="50%" persistent>
              <template v-if="visible" v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mx-2"
                  fab
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon blue>mdi-plus</v-icon></v-btn
                >
              </template>
              <v-card>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-toolbar dark color="primary">
                    <v-btn icon dark @click="dialog = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title
                      >{{ formTitle }}
                      {{ vehiculo.marca }}
                      {{ vehiculo.modelo }} ({{
                        vehiculo.patente
                      }})</v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn dark text @click="save" :disabled="!valid"
                        >Guardar</v-btn
                      >
                    </v-toolbar-items>
                  </v-toolbar>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="12" sm="12">
                          <v-autocomplete
                            :items="tipos"
                            item-value="id"
                            item-text="nombre"
                            label="Tipo de Documento"
                            v-model="editedItem.tipo_documento_id"
                            :rules="rulesRequired"
                            return-object
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="12" sm="12">
                          <v-text-field
                            v-model="editedItem.nombre"
                            label="Nombre"
                            :rules="rulesRequired"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <!-- subidor de foto -->
                        <v-col cols="12" md="12" sm="12">
                          <v-file-input
                            accept="image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            label="Seleccione una imagen"
                            prepend-icon="mdi-camera"
                            v-model="archivo"
                          ></v-file-input>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="12" sm="12">
                          <v-textarea
                            clearable
                            clear-icon="mdi-close-circle"
                            v-model="editedItem.observaciones"
                            label="Observaciones"
                            auto-grow
                            rows="1"
                            row-height="15"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-form>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-if="visible"
            small
            color="green"
            title="Editar"
            @click="editItem(item.id)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            v-if="visible"
            small
            color="red"
            title="Eliminar"
            @click="
              deleteItem(item.id, item.tipo_documento + ' - ' + item.nombre)
            "
          >
            mdi-delete
          </v-icon>
          <v-icon
            v-if="item.archivo"
            small
            color="blue"
            title="Descargar Documentacion"
            @click="download(item)"
          >
            mdi-file-download
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
function title() {
  return "Documentacion";
}

export default {
  props: {
    vehiculo: { Object, default: null },
  },
  data: (vm) => ({
    archivo: null,
    valid: true,
    _method: "PUT",
    autoGrow: true,
    rows: 1,
    title: title(),
    route: "documentacion",
    baseUrlVehiculo: "/api/documentacion-vehiculo",

    modal: false,

    dialog: false,
    snackbar: false,
    visible: true,
    text: "Registro Insertado",
    color: "success",
    timeout: 4000,
    rules: [(v) => v.length <= 100 || "Max 100 caracteres"],
    search: "",
    rulesRequired: [(v) => !!v || "Requerido"],
    headers: [
      { text: "Vehiculo", filterable: true, value: "patente" },
      {
        text: "Tipo Documento",
        filterable: true,
        value: "tipo_documento",
      },
      { text: "Nombre", filterable: true, value: "nombre" },
      { text: "Fecha", filterable: true, value: "created_at" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: { archivo: "" },
    tipos: [],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Registrar " + this.title
        : "Editar " + this.title;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
    this.getTipos();
  },

  methods: {
    respuesta(r, tipo = 1, excepcion = false) {
      let text1 = "Creado";
      let text2 = "crear";
      switch (tipo) {
        case 0:
          text1 = "Eliminado";
          text2 = "eliminar";
          break;
        case 2:
          text1 = "Actualizado";
          text2 = "actualizar";
          break;
        case 3:
          text1 = "Obtener";
          text2 = "actualizar";
          break;
      }
      if (excepcion == false) {
        if (tipo == 3) {
          if (r.data && r.data.status == 200) {
            this.editedIndex = r.data.data.id;
            delete r.data.data.id;
            this.editedItem = Object.assign({}, r.data.data);
            this.dialog = true;
          } else {
            console.log(r);
            this.snackbar = true;
            this.text =
              "<p>Ocurrió un problema al recuperar el registro.<br><hr><b>Codigo:</b>" +
              r.data.code +
              " - <b>Error:</b> " +
              r.data.message +
              "</p>";
            this.color = "error";
          }
        } else {
          if (r.data && r.data.status == 200) {
            this.color = "success";
            this.snackbar = true;
            this.text = "Registro " + text1;
            this.close();
            this.initialize();
          } else {
            this.snackbar = true;
            this.text =
              "<p>Ocurrió un problema al " +
              text2 +
              " el registro.<br><hr><b>Codigo:</b>" +
              r.data.code +
              " - <b>Error:</b> " +
              r.data.message +
              "</p>";
            this.color = "error";
          }
        }
      } else {
        this.snackbar = true;
        /*this.text =
                    "Error al " +
                    text2 +
                    " los datos. Error: " +
                    r.data.message;*/
        this.text =
          "Error al " +
          text2 +
          " los datos. Contacte al administrador del sistema. ";
        this.color = "error";
      }
    },
    download(item) {
      if (!item.archivo) {
        Swal.fire("Atención!", "El gasto no tiene archivos adjuntos", "info");
      }
      let fileJson = JSON.parse(item.archivo);
      let vehiculo = this.vehiculo.id;
      let archivo = fileJson.name;
      /*let url =
        "/api/vehiculos-download/" + vehiculo + "/" + archivo + "/gastos";*/
      let url = vehiculo + "/" + archivo + "/documentacion";
      this.$axiosApi
        .getDownload("vehiculos_download", url)
        /*axios({
        url: url,
        method: "GET",
        responseType: "blob",
      })*/
        .then((response) => {
          if (response.data.size <= 0) {
            Swal.fire(
              "Atención!",
              "El gasto no tiene archivos adjuntos",
              "info"
            );
            return;
          }
          let blob = new Blob([response.data], {
              type: response.data.type,
            }),
            url = window.URL.createObjectURL(blob);

          window.open(url);
        })
        .catch(console.error);
    },
    upload(id) {
      var fd = new FormData();
      var file = this.archivo;
      fd.append("vehiculo", id);
      let i = 0;
      if (file) {
        fd.append("file[" + i + "]", file, file.name);
        i++;
      }

      this.$axiosApi
        .postFile("vehiculos_upload_documentacion", fd)
        /*axios
        .post("/api/vehiculos-upload/gastos", fd, {
          headers: { "Content-Type": "multipart/form-data" },
        })*/
        .then((r) => {
          //subida exitosa
          console.log("upload ok");
          return true;
        })
        .catch((e) => {
          this.snackbar = true;
          this.text =
            "Error al cargar archivos. Contacte al administrador del sistema";
          this.color = "error";
        });
    },
    getTipos() {
      this.$axiosApi
        .get("tipo_documentos")
        .then((response) => {
          this.tipos = response.data;
        })
        .catch((response) => {
          this.snackbar = true;
          this.text = "Error al obtener tipos. Error: " + response.message;
          this.color = "error";
        });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    capitalize(s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    },

    initialize() {
      //let url = this.baseUrlVehiculo + "/" + this.vehiculo.id;
      /*axios
        .get(url)*/
      this.$axiosApi
        .getByCriteria("documentacion_vehiculo", this.vehiculo.id)
        .then((r) => {
          this.desserts = r.data;
        })
        .catch(function (error) {
          this.snackbar = true;
          this.text = "Error al obtener datos. Error: " + error;
          this.color = "error";
        });
    },

    editItem(id) {
      /*axios
        .get(this.baseUrl + "/" + id)*/
      this.$axiosApi
        .getById(this.route, id)
        .then((r) => {
          this.editedIndex = r.data.id;
          delete r.data.id;
          this.editedItem = Object.assign({}, r.data);
          if (this.editedItem.archivo) {
            let fileJson = JSON.parse(this.editedItem.archivo);
            let file = new File([fileJson], fileJson.name, {
              type: fileJson.type,
              lastModified: fileJson.lastModified,
              size: fileJson.size,
              webkitRelativePath: fileJson.webkitRelativePath,
            });
            //console.log(file);
            this.archivo = file;
          }
          this.editedItem.tipo_documento_id = r.data.tipo_documento_id
            ? {
                id: Number(r.data.tipo_documento_id),
                nombre: r.data.tipo_documento,
              }
            : null;
          this.dialog = true;
        })
        .catch(function (error) {
          this.snackbar = true;
          this.text = "Error al obtener datos. Error: " + error;
          this.color = "error";
        });
    },

    deleteItem(id, nombre) {
      this.$confirm({
        message: "¿Desea ELIMINAR la " + this.title + " " + nombre + "?",
        button: {
          no: "Cancelar",
          yes: "Confirmar",
        },

        callback: (confirm) => {
          if (confirm) {
            this.$axiosApi.delete(this.route, id).then(
              (response) => {
                this.snackbar = true;
                this.text = "Registro Eliminado";
                this.initialize();
              },
              (error) => {
                this.snackbar = true;
                this.text =
                  "Error al eliminar los datos.Contacte al administrador del sistema";
                this.color = "error";
              }
            );
          }
        },
      });
    },

    close() {
      this.dialog = false;
      this.valid = true;
      this.resetValidation();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    save() {
      if (this.$refs.form.validate()) {
        this.editedItem.vehiculo_id = this.vehiculo.id;
        if (this.archivo) {
          var newObject = {
            lastModified: this.archivo.lastModified,
            lastModifiedDate: this.archivo.lastModifiedDate,
            name: this.archivo.name,
            size: this.archivo.size,
            type: this.archivo.type,
          };

          // then use JSON.stringify on new object
          this.editedItem.archivo = JSON.stringify(newObject);
        }
        if (this.editedIndex > -1) {
          this.$axiosApi
            .put(this.route, this.editedIndex, this.editedItem)
            .then((r) => {
              if (r.data.status == 200) {
                if (this.archivo) {
                  this.upload(this.vehiculo.id);
                }
                this.respuesta(r, 2);
              } else {
                this.respuesta(r, 2, true);
              }
            })
            .catch((e) => {
              console.log(e);
              this.respuesta(e, 2, true);
            });
        } else {
          this.$axiosApi
            .post(this.route, this.editedItem)
            .then((r) => {
              if (typeof r !== "undefined" && r !== null) {
                if (this.archivo) {
                  this.upload(this.vehiculo.id);
                }
                this.respuesta(r, 1);
              } else {
                this.respuesta(r, 1, true);
              }
            })
            .catch((e) => {
              console.log(e);
              this.respuesta(e, 1, true);
            });
        }

        this.close();
      }
    },
  },
  mounted() {
    console.log("Componente " + title() + " creado");
  },
};
</script>
