export default {

    install(Vue, options) {


        Vue.prototype.$initialize= function() {
            let vm = this;

            vm.$axiosApi
                .get(vm.route)
                .then((r) => {
                    vm.desserts = r.data;
                })
                .catch(function (error) {
                    vm.snackbar = true;
                    vm.text = "Error al obtener datos. Error: " + error;
                    vm.color = "error";
                });
        },

        Vue.prototype.$enabledItem= function(item) {
            let vm = this;
            let id = item.id;
            let titulo = vm.title;
            if (titulo.substring( titulo.length - 1)== 's')
             titulo = titulo.substring(0, titulo.length - 1);
            let registro = (item.nombre)?item.nombre:'';
            let activar = item.activo == 1 ? 0 : 1;
            let form = {'activo':activar};            
            let msj =
                item.activo == 1
                    ? "Desea desactivar el " + titulo + " " + registro + " ?"
                    : "Desea activar el " + titulo + " " + registro + " ?";

                    vm.$confirm({
                message: msj,
                button: {
                    no: "Cancelar",
                    yes: "Confirmar",
                },

                callback: (confirm) => {
                    let respuesta = item.activo == 1 ? 4 : 5;
                    if (confirm) {
                        vm.$axiosApi
                            //.put(url)
                            .put(vm.route+'_enable', id, form)
                            .then((r) => {
                                vm.$respuesta(vm, r, respuesta);
                            })
                            .catch((e) => {
                                vm.$respuesta(vm, e, respuesta, true);
                            });
                    }
                },
            });
        }


        Vue.prototype.$deleteItem=  function (id, nombre) {
            let vm = this;

              let titulo = vm.title;
            if (titulo.substring( titulo.length - 1)== 's')
             titulo = titulo.substring(0, titulo.length - 1);

            vm.$confirm({
                message: "¿Desea ELIMINAR el " + titulo + " " + nombre + "?",
                button: {
                    no: "Cancelar",
                    yes: "Eliminar",
                },

                callback: async function (confirm)  {


                    if (confirm) {
                        vm.$alertGetInfo("Eliminando Registro");

                        await vm.$axiosApi
                            .delete(vm.route, id)
                            .then((r) => {
                                vm.$respuesta(vm, r, 0);
                            })
                            .catch((e) => {
                                vm.$respuesta(vm, e, 0, true);
                            });
                            Swal.close();
                    }
                },
            });

        }



        Vue.prototype.$save = async function() {
            let vm = this;

            if (vm.$refs.form.validate()) {

                if (vm.editedIndex > -1) {
                  vm.$alertGetInfo("Actualizando información");

                  await vm.$axiosApi.put(vm.route, vm.editedIndex, vm.editedItem)
                    .then((r) => {
                        vm.$respuesta(vm, r, 2);

                    }).catch((e) => {
                        vm.$respuesta(vm, e, 2, true);

                    });

                } else {
                  vm.$alertGetInfo("Registrando información");

                  await vm.$axiosApi.post(vm.route, vm.editedItem)
                    .then((r) => {
                        vm.$respuesta(vm, r, 1);
                    }).catch((e) => {
                        vm.$respuesta(e, 1, true);
                    });
                }
                Swal.close();
                vm.$close();
            }
        }

        Vue.prototype.$editItem = async function(id){
            let vm = this;
            vm.$alertGetInfo("Obteniendo información <br><b></b>");
            /*vm.snackbar = true;
            vm.text = "Obteniendo datos ";
            vm.color = "primary";
            */
            await vm.$axiosApi
            .getById(vm.route, id)
                .then((r) => {
                    //console.log(r.data);
                    Vue.prototype.$respuesta(vm, r, 3);
                    Swal.close();
                    //vm.snackbar = false;

                })
                .catch(function (error) {
                    Vue.prototype.$respuesta(vm, error, 3, true);
                });


        }


        Vue.prototype.$formatDate= function (date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        }

        Vue.prototype.$close= function() {

            let vm = this;

            vm.dialog = false;
            vm.valid = true;
            vm.$resetValidation();
            vm.$nextTick(() => {
                vm.editedItem = Object.assign({}, vm.defaultItem);
                vm.editedIndex = -1;
            });
        }

        Vue.prototype.$resetValidation= function() {
            let vm = this;
            vm.$refs.form.resetValidation();
        },


        Vue.prototype.$capitalize =function(s) {
            if (typeof s !== "string") return "";
            return s.charAt(0).toUpperCase() + s.slice(1);
        },

        Vue.prototype.$getQuery = async function(url) {
            let vm = this;

            let records =  await vm.$axiosApi
                .get(url)
                .then((r) => {

                    return r.data;
                })
                .catch(function (error) {
                    vm.snackbar = true;
                    vm.text = "Error al obtener datos. Error: " + error;
                    vm.color = "error";
                });
                //console.log('records');
                //console.log(records);
            return records;
        },

        Vue.prototype.$respuesta = function(vm,r,tipo,excepcion  = false){

          let text1 = "Creado";
          let text2 = "crear";
          switch (tipo) {
            case 0:
              text1 = "Eliminado";
              text2 = "eliminar";
              break;
            case 2:
              text1 = "Actualizado";
              text2 = "actualizar";
              break;
            case 3:
              text1 = "Obtener";
              text2 = "actualizar";
              break;
            case 4:
              text1 = "Desactivado";
              text2 = "desactivar";
              break;
            case 5:
              text1 = "Activo";
              text2 = "activar";
              break;
          }

          if (excepcion == false) {

            if (tipo == 3) {

              if (r.data && r.data.status == 200) {
                vm.editedIndex = r.data.data.id;
                delete r.data.data.id;
                
                vm.editedItem = Object.assign({}, r.data.data);
                vm.dialog = true;
              } else {

                vm.snackbar = true;
                vm.text =
                  "<p>Ocurrió un problema al recuperar el registro.<br><hr><b>Codigo:</b>" +
                  r.data.code +
                  " - <b>Error:</b> " +
                  r.data.message +
                  "</p>";
                vm.color = "error";
              }
            } else {
              if (r.data && r.data.status == 200) {
                vm.color = "success";
                vm.snackbar = true;
                vm.text = "Registro " + text1;


                if (tipo == 1 || tipo == 2) {
                  vm.$close();
                }
                vm.$initialize();
              } else {
                vm.snackbar = true;
                vm.text =
                  "<p>Ocurrió un problema al " +
                  text2 +
                  " el registro.<br><hr><b>Codigo:</b>" +
                  r.data.code +
                  " - <b>Error:</b> " +
                  r.data.message +
                  "</p>";
                vm.color = "error";
              }
            }
          } else {
            console.log(r);
            vm.snackbar = true;
            vm.text = "Error al " + text2 + " los datos. Error: " + r.message;
            vm.color = "error";
          }
        }


        Vue.prototype.$getCss= function (value, item)
        {
          let verde = "grid-green rounded-pill text-center p-1k mr-9";
          let amarillo = "grid-yellow rounded-pill text-center mr-9";
          let rojo = "grid-red rounded-pill text-center p-1 mr-9";

          switch (item) {
            case "activo":
              if (value== "1") return verde;
              if (value  === "") return "white" ;
              else return rojo;
              break;
          }
        }

        Vue.prototype.$getDate = function () {
            return Math.floor(Date.now() / 1000);
        }

        Vue.prototype.$getValue= function (value, item)
        {


          switch (item) {
            case "activo":

              if (value== 1) return "Si";
              if (value == 0) return "No";
              if (value == null) return "";
            break;
          }
        }

        Vue.prototype.$alertGetInfo= function (msgHtml)
        {

          Swal.fire({
            title: "Aguarde un instante!",
            icon: "info",
            html: msgHtml,
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });
        }


        Vue.prototype.$filteredData=  function (item)
        {
          let vm = this;
          let conditions = [];
          for (var k in vm.filters) {
					   if (vm.filters[k].length >0) {
							vm.filterKey =k;

              let a ="vm.filterBy"+k.charAt(0).toUpperCase() + k.slice(1);;

              conditions.push( eval(a));
          	}
					}



          if (conditions.length > 0) {
            return vm.desserts.filter((dessert) => {
              return conditions.every((condition) => {
                return condition(dessert);
              });
            });
          }

          return vm.desserts;
        }


        Vue.prototype.$filterBy= function (item,filterKey)
        {
            let k = filterKey;


            switch(k)
            {
              case "activo":
                if (this.filters[k].toLowerCase() == "si")
                return item[k]==1;
              if (this.filters[k].toLowerCase() == "no")
                return item[k]==0;
              break;
              case "nombre":
              case "apellido":
              case "dni":
                  return String(item[k]).toLowerCase().includes(this.filters[k].toLowerCase());
              break;
            }


      }





    //////////////////////////////////////////////////////////////////////
    Vue.prototype.$filteredData2=  function (item)
        {
          let vm = this;
          let conditions = [];




          for (var k in vm.filters) {
					   if (vm.filters[k].length >0) {
							vm.filterKey =k;


              //conditions.push( vm.filterData);
              vm.filterData(k);



              //conditions.push( eval(a));
          	}
					}

/*

          if (conditions.length > 0) {
            return vm.desserts.filter((dessert) => {
              return conditions.every((condition) => {
                return condition(dessert);
              });
            });
          }*/

          return vm.desserts;
        }


        Vue.prototype.$filterBy= function (item,filterKey)
        {
            let k = filterKey;

            if (k == "activo") {
              if (this.filters[k].toLowerCase() == "si")
                return item[k]==1;
              if (this.filters[k].toLowerCase() == "no")
                return item[k]==0;

            }

            return String(item[k])
              .toLowerCase()
              .includes(this.filters[k].toLowerCase());

      }

       Vue.prototype.$filterBy2= function (item)
       {
            console.log("item")
            console.log(item);
            console.log(arguments)
     }







    }
}
